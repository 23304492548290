import React, { Component } from 'react'
import styles from './Footer.module.css'
import logo from '../images/wistor-logo-white-with-text.svg'
import { withRouter } from 'react-router-dom'

class Footer extends Component {
  render() {
    //var keuze="normaal";
    var keuze = 'wistor'

    try {
      if (this.props.location.pathname.includes('waternet')) {
        keuze = 'waternet'
      }
    } catch (er) {}
    try {
      if (this.props.location.pathname.includes('kvk')) {
        keuze = 'kvk'
      }
    } catch (er) {}
    try {
      if (this.props.location.pathname.includes('buildingbits')) {
        keuze = 'buildingbits'
      }
    } catch (er) {}
    try {
      if (this.props.location.pathname.includes('rws')) {
        keuze = 'buildingbits'
      }
    } catch (er) {}
    try {
      if (this.props.location.pathname.includes('connectedapps')) {
        keuze = 'buildingbits'
      }
    } catch (er) {}
    try {
      if (this.props.location.pathname.includes('mayer')) {
        keuze = 'mayer'
      }
    } catch (er) {}
    return (
      <div className={styles.wrapper}>
        <div className={styles.main}>
          <a href="http://wistor.nl">
            <div>
              <img src={logo} alt="logo" style={{ height: '20px', marginRight: '6px', marginTop: '6px', marginBottom: '6px' }}/>
            </div>
          </a>
          {
            {
              normaal: (
                <div className={styles.names}>
                  <div>
                    <a
                      className={styles.email}
                      href="https://www.mayersoftwaredevelopment.nl/"
                    >
                      mayersoftwaredevelopment
                    </a>
                  </div>
                  <div>
                    <a
                      className={styles.email}
                      href="http://wistor.nl"
                    >
                     Wistor
                    </a>
                  </div>
                </div>
              ),
              waternet2: (
                <div className={styles.commentMessage}>
                  Als u vragen of opmerkingen heeft kun u een bericht sturen
                  naar{' '}
                  <a
                    className={styles.email}
                    href="mailto:otlviewer@waternet.nl"
                  >
                    OTLviewer@waternet.nl
                  </a>
                </div>
              ),
              kvk: (
                <div className={styles.commentMessage}>
                  <div>
                    <a className={styles.email} href="http://www.kvk.nl">
                      KVK Innovatielab
                    </a>
                  </div>
                </div>
              ),
              buildingbits: (
                <div className={styles.names}>
                  <div>
                    <a
                      className={styles.email}
                      href="https://wistor.nl"
                    >
                      Wistor
                    </a>
                  </div>
                </div>
              ),
              wistor: (
                <div className={styles.names}>
                  <div>
                    <a
                      className={styles.email}
                      href="https://wistor.nl"
                    >
                      Wistor
                    </a>
                  </div>
                </div>
              ),
              mayer: (
                <div className={styles.names}>
                  <div>
                    <a
                      className={styles.email}
                      href="https://www.mayersoftwaredevelopment.nl/"
                    >
                      mayersoftwaredevelopment
                    </a>
                  </div>
                </div>
              ),
            }[keuze]
          }

          {/* <div className={styles.names}>
            <div>Michiel Mayer</div>
            <div>Hans Schevers</div>
          </div> */}
        </div>
      </div>
    )
  }
}

Footer = withRouter(Footer)

export default Footer
