import * as React from "react"
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons"

import { Button } from "./button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "./popover"
import { mergeClasses } from "../../helpers/styling"

/**
 * Inspired by https://ui.shadcn.com/docs/components/combobox
 */
export function InputWithSuggestions<T extends string,>(props: {
  selected?: T, suggestions: T[], placeholder: string,
  select: (value: string) => void, noSuggestionsPlaceholder: string,
}) {
  const [input, setInput] = React.useState(props.selected ?? '')
  const [open, setOpen] = React.useState(false)

  const suggestionsWithCurrentInput =
    props.suggestions.some(suggestion => suggestion === input) || !input.length
      ? props.suggestions
      : [input, ...props.suggestions]

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full justify-between"
        >
          {props.suggestions.find(suggestion => suggestion === props.selected)
            ?? props.placeholder}
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0" style={{zIndex: 9999, width: 'var(--radix-popper-anchor-width)'}}>
        <Command>
          <CommandInput className="h-9" placeholder={props.placeholder}
            value={input} onValueChange={setInput}
          />
          <CommandList>
            <CommandEmpty>{props.noSuggestionsPlaceholder}</CommandEmpty>
            <CommandGroup>{suggestionsWithCurrentInput.map((suggestion) => (
              <CommandItem key={suggestion} value={suggestion} onSelect={(value) => {
                props.select(value)
                setOpen(false)}
              }>
                {suggestion}
                <CheckIcon className={mergeClasses(
                  "ml-auto h-4 w-4",
                  props.selected === suggestion ? "opacity-100" : "opacity-0")} />
              </CommandItem>))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
