import { useState } from 'react'
import styles from './DeleteAppConfigButton.module.css'
import { Icon } from './widgets/Icon'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

type State = { confirming: boolean, deleting: boolean }

const initialState: State = { confirming: false, deleting: false }

export function DeleteAppConfigButton(props: {
  title: string
  createdBy: string
  createdOn: string
  deleteAppConfig: () => Promise<any>
}) {
  const [state, setState] = useState<State>(initialState)

  return (
    <>
      <Icon icon="trash" className={styles.deleteIcon}
        onClick={(e) => {
          e.stopPropagation()
          setState(s => ({ ...s, confirming: true}))
        }}
      />

      <Dialog open={state.confirming}>
        <DialogTitle>Deletion of "{props.title}"</DialogTitle>

        <DialogContent>
          <p>Are you sure you want to delete this project?</p>

          Project details:
          <ul className="list-disc list-inside">
            <li>Title: {props.title}</li>
            <li>Created by: {props.createdBy}</li>
            <li>Created on: {props.createdOn}</li>
          </ul>
        </DialogContent>

        <DialogActions>
          <Button autoFocus
            variant="contained"
            color="grey"
            disabled={state.deleting}
            onClick={(e) => {
              e.stopPropagation()
              setState(s => ({ ...s, confirming: false }))
            }
          }>
            Keep
          </Button>
          <Button
            variant="contained"
            color="error"
            disabled={state.deleting}
            onClick={async (e) => {
              e.stopPropagation()
              setState(s => ({ ...s, deleting: true }))
              await props.deleteAppConfig()
              setState(s => ({ deleting: false, confirming: false }))
            }
          }>
            {state.deleting ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}