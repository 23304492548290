import './bbutil'
import BBIFrame from './BBIFrame'

class BBBoorkern extends BBIFrame {
  getUrl = () => `/${window.bbFolderName}/boorkern/vboorkern.html`

  constructor() {
    super()
    //console.log("boorkern Constructor");
    this.scrolling = 'no'
    this.noEvents = false;
    this.usesParameters=false;
  }

  static getDescription() {
    return {
      name: 'BBBoorkern',
      component: BBBoorkern,
      label: 'virtuele boorkern',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label: 'to fill the map. ',
        },
        {
          name: 'height',
          type: 'text',
          label: 'height variable',
        },
        {
          name: 'publishVariable',
          type: 'text',
          label:
            'Variabele in which an item URI is published, when a feature in the figure is clicked.',
        },
        {
          name: 'subscribeVariable',
          type: 'text',
          label:
            'Published uri variabele on which to listen to define selection.(not yet implemented). use selectedUri instead  (set a default value via ::) ',
        },
        {
          name: 'className',
          type: 'text',
          label: 'iframe classNames',
        },
      ],
    }
  }

  state = { selectedUri: null }

  reExecuteSparql() {
    // console.log("re execute sparql called ");
    this.updateMe()
  }
}
export default BBBoorkern
