import { Component, cloneElement } from 'react'
import axios from 'axios'
import isEqual from 'lodash/isEqual'
import debounce from 'lodash/debounce'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { Loader } from './Loader'
import { getCgiUrl } from '../helpers/url'
import { ErrorNeedAuth, SparqlTemplate } from "../helpers/SparqlManager"
import { NoPlaceholderValueError, resolveTemplateString } from '../helpers/resolve-template-string'

const SparqlClient = require('sparql-http-client/SimpleClient')

axios.defaults.timeout = 250000; 

const CancelToken = axios.CancelToken

class Widget extends Component {
  constructor(props) {
    super(props)
    this.resolveTemplateVariableInQueryDebounce = debounce(
      this.resolveTemplateVariableInQuery,
      2000
    )
    this.useLoadingWidget = false
    if (window.top.url == null) {
      this.createSparqlFunction()
    }

    const endpoint = getCgiUrl('sparql', this.props.repoName, this.props.repoURL, this.props.localRepoURL)
    this.sparql = new SparqlTemplate(endpoint, this.props.widgetId)
  }

  lastQuery = undefined
  state = {
    loading: false,
  }

  getUrl() {
    try {
      let url = '/api/sparql/' + this.props.repoURL
      if (this.props.repoURL.startsWith('http')) {
        // request sparql endpoint directly
        url = this.props.repoURL
      }
      var modus = false
      if (window.location.host === 'localhost:3000') {
        modus = true
      }
      //  var modus=false; //debug

      if (
        this.props.localRepoURL != null &&
        this.props.localRepoURL !== '' &&
        modus
      ) {
        url = this.props.localRepoURL
      }
      window.top.url = url
      try {       document.url=url } catch(e){}
    //  console.log('returning url :' + url)
      return url
    } catch (e) {
      console.log('error creating url' + e)
    }
    return null
  }

  createSparqlFunction2() {
    // gebruikt door bb widgets in iframe situation
    var url = this.getUrl()
    window.top.query = async function (query, success, errorRV) {
      // console.log("query via window top ",query,success,errorRV);

      let encodedQuery = encodeURIComponent(query)
      let data = {
        query: query,
      }
      data = 'infer=false&sameAs=false&query=' + encodedQuery

      axios.defaults.headers.post['Content-Type'] =
        'application/x-www-form-urlencoded'
      //axios.defaults.headers.post['Access-Control-Allow-Origin'] ='*';

      //console.log("querying with ",query,headers);

      
    
    if (document.withCredentials==null) {document.withCredentials=true}

      axios.defaults.withCredentials = document.withCredentials;
      if (document.withCredentials !=false)
      {
        axios.defaults.credentials = 'include'
      }
    
      
       if  ( !( (success==null)  && (errorRV==null)) ) 
       {
          var r=axios({  method: 'post',     url,      data,      maxContentLength: Infinity,
          maxBodyLength: Infinity,   headers:{       Accept: 'application/sparql-results+json'      },     cancelToken: new CancelToken((c) => {         this.cancel = c       }),       }); 
          
            r.then((response) => {
              if (success) {
                success.call(this, response.data)
              }
            })
            .catch((error) => {
              //  console.log(error,query);
              if (errorRV) {
                errorRV.call(this, error)
              }
            })
          }
       
      
        else
        {
             console.log("run await version");
        //  var r= await axios({  method: 'post',     url,  data,       headers:{       Accept: 'application/sparql-results+json'      },     cancelToken: new CancelToken((c) => {         this.cancel = c       }),       }).catch(err => false);; 
        var r= await axios.post(url,data,{   maxContentLength: Infinity,
          maxBodyLength: Infinity, headers:{       Accept: 'application/sparql-results+json'      },     cancelToken: new CancelToken((c) => {         this.cancel = c       }),       });; 
         
         
            return  r;
        }
      }
  

    this.sparqlQuery = window.top.query;
    
  }

  createSparqlFunction() {
    // gebruikt door bb widgets in iframe situation

    if (true) {
      this.createSparqlFunction2()
      return
    }
    var endpointUrl = this.getUrl()
    window.top.query = async function (query, success, errorRV) {
      try {
        const client = new SparqlClient({ endpointUrl,headers:{
          Accept: 'application/sparql-results+json'
        } })
        
        const stream = await client.query.select(query, {
          operation: 'postUrlencoded',
        })
        var data = await stream.json()

        if (success) {
          success.call(this, data)
        }
      } catch (e) {
        console.log(e)
        if (errorRV != null) {
          errorRV.call(this, 'error sparql endpoint')
        }
      }
    }
  }

  runJSInQuery(query) {
    try {
      if (query == null) return null
      let b = query.includes('<script>')
      if (b) {
      //  console.log('FOUND JS in query')

        let codes = query.split('<script>')
        let nquery = ''
        for (let n in codes) {
          if (n === '0') {
            nquery = codes[n]
            continue
          }
          let code = codes[n]

          let codeQuery = code.split('</script>')
          let evalcode = codeQuery[0]
          //  console.log(code,n,evalcode);
          let evalCode2 = new Function(evalcode)() //eslint-disable-line no-new-func
          if (evalCode2 == null) {
            evalCode2 = ''
          }
          let subQuery2 = codeQuery[1]
          nquery += evalCode2 + subQuery2
        }
      //  console.log('returning ', nquery)
        return nquery
      }
    } catch (error) {
      console.error('error parsing js in query ', error, query)
    }

    return query
  }

  async loadData(originalQuery, countRetries = 0) {
    if (countRetries > 1) return console.error('retried this already')

    if (!this.props.repoURL) return console.error('Repo URL is not set!')
    if (!originalQuery) return

    const query = this.runJSInQuery(originalQuery)

    if (countRetries === 0) {
      this.setLoading(true)
      this.setState({ message: null })
    }

    try {
      const response = await this.sparql.fetch(query)
      if (!response.isLatest()) return

      this.setState({ data: response.result })
      this.setLoading(false)
    } catch(error) {
      if (error instanceof axios.CanceledError) {
        return
      }

      if (error instanceof ErrorNeedAuth) {
        const auth = await this.props.reauth()
        if (!auth.success) {
          auth.goToLoginPage()
          return
        }
        return this.loadData(originalQuery)
      }

      console.error(error)
      return this.loadData(originalQuery, countRetries + 1)
    }
  }

  /**
   * @param changedPublishProps {string[]|null}
   */
  resolveTemplateVariableInQuery = (changedPublishProps) => {
    if (this.props.withinSelectedTab === false) return // geen queries uitvoeren voor niet zichtbare tabs
    if (!this.props.query) return

    try {
      const query = resolveTemplateString(this.props.query, this.props.pubsub, changedPublishProps)
      if (query === this.lastQuery) return

      this.lastQuery = query
      this.loadData(query)
    } catch(e) {
      if (e instanceof NoPlaceholderValueError) return this.setState({ message: '' }) //message: `Kies een ${subscribeProp}`
      console.error(e)
    }
  }

  componentDidMount() {
    let variables = this.props.definition.variables
    let queryDefinition =
      variables && variables.find((variable) => variable.name === 'query')
    if (!queryDefinition) return
    if (this.props.withinSelectedTab === false) return
    this.resolveTemplateVariableInQuery()
  }

  componentDidUpdate(prevProps) {
    let variables = this.props.definition.variables
    let queryDefinition =
      variables && variables.find((variable) => variable.name === 'query')
    if (!queryDefinition) return

    if (prevProps.repoURL !== this.props.repoURL) {
      this.resolveTemplateVariableInQueryDebounce()
    } else if (this.props.withinSelectedTab !== prevProps.withinSelectedTab) {
      // if (this.props.withinSelectedTab && !this.state.data){
      if (this.props.withinSelectedTab) {
        this.resolveTemplateVariableInQuery()
      }
    } else if (prevProps.query !== this.props.query) {
      this.resolveTemplateVariableInQuery()
    } else if (!isEqual(prevProps.pubsub, this.props.pubsub)) {
      let changedPublishProps = []

      Object.keys(this.props.pubsub).forEach((key) => {
        if (prevProps.pubsub[key] !== this.props.pubsub[key]) {
          changedPublishProps.push(key)
        }
      })

      this.resolveTemplateVariableInQuery(changedPublishProps)
    }
  }

  publishMultipleValues=(prop_value)=>
  this.props.dispatch({ type: 'PUBLISH', data: prop_value })

  publish = (prop, value) =>
    this.props.dispatch({ type: 'PUBLISH', data: { [prop]: value } })

  /**
   * @param loading {boolean}
   */
  setLoading = loading => {
    this.setState({ loading })

    if (this.props.voteForUserActionsDisabledWhenLoading) {
      this.props.voteForUserActionsDisabled(loading)
    }
  }

  /**
   * @param loading {boolean}
   */
  setLoadingFromWidget = loading => {
    /*
     * This setTimeout was taken from existing code and its purpose is unclear.
     * It may indicate a potential code problem or may not be needed at all.
     * Further investigation is required to determine if it should be removed.
     */
    setTimeout(() => {
      this.setLoading(loading)
    }, 0);
  }

  sendNotification = (msg) => toast(msg)

  render() {
    if (this.state == null) return null

    const { children, ...propsWithoutChildren } = this.props
    return (
      <Loader active={this.state.loading && this.props.showLoadingOverlay}>
        {cloneElement(children, {
          ...this.state,
          ...propsWithoutChildren,
          setLoading: this.setLoadingFromWidget,
          publish: this.publish,
          publishMultipleValues:this.publishMultipleValues,
          sendNotification: this.sendNotification,
          closeDialog2:this.props.closeDialog,
        })}
      </Loader>
    )
  }
}

const mapStateToProps = function (state) {
  return {
    layoutName: state.project.layoutName,
    pubsub: state.pubsub
  }
}

export default connect(mapStateToProps)(Widget)