import { Button } from '@mui/material'
import { Component } from 'react'
import projectStyles from '../../Project.module.css'
import buttonStyles from './Button.module.css'
import { Icon } from '../Icon'
import { FontList } from "../../FontList"

class BBLinkButtons extends Component {
  static getDescription() {
    return {
      name: 'BBLinkButtons',
      component: BBLinkButtons,
      label: 'BB Link Buttons',
      variables: [
        {
          name: 'labels',
          type: 'array',
          label: 'label,label2,etc',
          topicValueInterpolationEnabled: true,
        },
        {
          name: 'links',
          type: 'array',
          label: 'url1,url2,etc',
          topicValueInterpolationEnabled: true,
        },
        {
          name: 'openLinksInTheSameTab',
          type: 'boolean',
          label: 'Open links in the same tab'
        },
        {
          name: 'title',
          type: 'text',
          label: 'titel',
        },
        {
          name: 'beginIcons',
          type: 'array',
          label: 'begin-icons: "icon1,icon2,etc"'
        },
        {
          name: 'endIcons',
          type: 'array',
          label: 'end-icons: "icon1,icon2,etc"'
        },
        {
          name: 'customStyling',
          type: 'boolean',
          label: 'Enable Custom Styling',
        },
        {
          name: 'buttonBorderRadius',
          type: 'text',
          label: 'Custom Styling: button-border-radius (examples: "2lh", "50%", "1.2em"). See https://developer.mozilla.org/en-US/docs/Web/CSS/border-radius',
        },
        {
          name: 'textColor',
          type: 'text',
          label: 'Custom Styling: text-color (examples: "green", "#FF0000", "hsl(0 100% 50% / 50%)"). See https://developer.mozilla.org/en-US/docs/Web/CSS/color_value',
        },
        {
          name: 'bgColor',
          type: 'text',
          label: 'Custom Styling: background-color (examples: "green", "#FF0000", "hsl(0 100% 50% / 50%)"). See https://developer.mozilla.org/en-US/docs/Web/CSS/color_value',
        },
        {
          name: 'hasButtonShadow',
          type: 'boolean',
          label: 'Custom Styling: enable button-shadow'
        },
        {
          name: 'marginBetweenButtons',
          type: 'text',
          label: 'Custom Styling: margin-between-buttons (example: "0.4em")',
        },
        {
          name: 'buttonContainerPadding',
          type: 'text',
          label: 'Custom Styling: button-container-padding (example: "0.4em")'
        },
        {
          name: 'buttonFontSize',
          type: 'text',
          label: 'Custom Styling: button-font-size'
        },
        {
          name: 'buttonFontFamily',
          type: 'text',
          label: 'Custom Styling: button-font-family',
          helpComponent: FontList
        },
      ],
    }
  }

  render() {
    const fontSize = this.props.buttonFontSize ? this.props.buttonFontSize : 'inherit'
    const fontFamily = this.props.buttonFontFamily ? this.props.buttonFontFamily : 'inherit'
    return (
      <div
        className={buttonStyles.buttonsContainer}
        data-custom-styling={Boolean(this.props.customStyling)}
        style={{
          '--button-border-radius': this.props.buttonBorderRadius,
          '--text-color': this.props.textColor,
          '--background-color': this.props.bgColor,
          '--margin-between-buttons': this.props.marginBetweenButtons,
          '--button-container-padding': this.props.buttonContainerPadding,
          '--button-font-size': fontSize,
          '--button-font-family': fontFamily,
        }}
      >
        {this.props.title && (
          <div className={projectStyles.title}>{this.props.title}</div>
        )}
        {this.props.labels?.map((label, i) => {

          if (!this.props.customStyling) return (
            <OriginalButton
              key={i}
              href={this.props.links?.[i]}
              openLinkInSameTab={this.props.openLinksInTheSameTab}
              beginIcon={this.props.beginIcons?.[i]}
              endIcon={this.props.endIcons?.[i]}
            >
              {label}
            </OriginalButton>
          )

          return (
            <CustomStyleButton
              key={i}
              href={this.props.links?.[i]}
              openLinkInSameTab={this.props.openLinksInTheSameTab}
              beginIcon={this.props.beginIcons?.[i]}
              endIcon={this.props.endIcons?.[i]}
              hasButtonShadow={this.props.hasButtonShadow}
            >
              {label}
            </CustomStyleButton>
          )
         })}
      </div>
    )
  }
}

function OriginalButton(props) {
  return (
    <a
      className={projectStyles.viewer}
      style={{ textDecoration: 'none', color: 'inherit' }}
      href={props.href}
      target={props.openLinkInSameTab ? "_self" : "_blank"}
      rel="noopener noreferrer" // For security reasons when using target="_blank"
    >
      <div className={projectStyles.titleText}>
        {props.beginIcon && <Icon icon={props.beginIcon} />}
        {props.children}
        {props.endIcon && <Icon icon={props.endIcon} />}
      </div>
    </a>
  )
}

function CustomStyleButton(props) {
  return (
    <Button
      component="a"
      className={buttonStyles.button}
      style={{ textDecoration: 'none',  color: 'inherit' }}
      onClick={props.onClick}
      href={props.href}
      target={props.openLinkInSameTab ? "_self" : "_blank"}
      rel="noopener noreferrer" // For security reasons when using target="_blank"
      color="grey"
      variant="contained"
      endIcon={props.endIcon && <Icon icon={props.endIcon} />}
      startIcon={props.beginIcon && <Icon icon={props.beginIcon} />}
      data-has-button-shadow={Boolean(props.hasButtonShadow)}
    >
      {props.children}
    </Button>
  )
}

export default BBLinkButtons
