import './bbutil'
import BBIFrame from './BBIFrame'

class BBNOML extends BBIFrame {
  getUrl = () => `/${window.bbFolderName}/noml.html`

  constructor() {
    super()

    this.scrolling = 'no'
    this.noEvents = false
  }

  static getDescription() {
    return {
      name: 'BBNOML',
      component: BBNOML,
      label: 'BBNOML',
      variables: [
        {
          name: 'data',
          type: 'textarea',
          label: 'noml input',
        },
      ],
    }
  }
}
export default BBNOML
