import Yasqe from '@triply/yasqe'

export type CurrentViewerState = {
  appCss: Record<string, string | number>
  activeYasqes: Yasqe[]
  votersForGlobalUserActionsDisabled: Set<string>
}

const initialState: CurrentViewerState = {
  appCss: {},
  activeYasqes: [],
  votersForGlobalUserActionsDisabled: new Set<string>(),
}

type Action
  = {type: "VOTE_USER_ACTIONS_DISABLED", data: {value: boolean, voterId: string}}
  | {type: "SET_APP_CSS", data?: Record<string,string>}
  | {type: "ADD_YASQE", data: Yasqe}
  | {type: "REMOVE_YASQE", data: Yasqe}

export default function currentViewerReducer(state: CurrentViewerState = initialState, action: Action): CurrentViewerState {
  switch (action.type) {
    case "ADD_YASQE":
      return {...state, activeYasqes: [...state.activeYasqes, action.data]}
    case 'REMOVE_YASQE':
      return {...state, activeYasqes: state.activeYasqes.filter(x => x !== action.data)}
    case "SET_APP_CSS":
      return {...state, appCss: action.data ?? {}}
    case "VOTE_USER_ACTIONS_DISABLED":
      const newVoters = new Set(state.votersForGlobalUserActionsDisabled)
      if (action.data.value)
        newVoters.add(action.data.voterId)
      else
        newVoters.delete(action.data.voterId)

      return { ...state, votersForGlobalUserActionsDisabled: newVoters }
  }

  return state
}

export function isUserActionsDisabled(state: CurrentViewerState) {
  return state.votersForGlobalUserActionsDisabled.size > 0
}
