export const FETCH_VIEWER_SUCCESS = 'FETCH_VIEWER_SUCCESS'
export const FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS'
export const SAVE_VIEWER_SUCCESS = 'SAVE_VIEWER_SUCCESS'
export const DELETE_APP_SUCCESS = 'DELETE_APP_SUCCESS'
export const SET_LAYOUT_NAME = 'SET_LAYOUT_NAME'

const initialState = {
  loading: true,
  viewers: [],
}

export default function handleProject(state = initialState, action) {
  switch (action.type) {

    case FETCH_PROJECT_SUCCESS:
      return {
        ...state,
        ...action.data,
        viewers: action.data.viewers
          .filter(v => !v.deleted)
          .map(v => ({ ...v, loaded: false })),
        loading: false,
      }

    case FETCH_VIEWER_SUCCESS:
     
      window.top.data = action.data
        if (action.data==null)
        {
          return {
            ...state,
            viewers: [
              ...state.viewers.filter((item) => item.id !== null),
              { ...action.data, loaded: true },
            ],
          }
        }
      return {
        ...state,
        viewers: [
          ...state.viewers.filter((item) => item.id !== action.data.id),
          { ...action.data, loaded: true },
        ],
      }

    case SAVE_VIEWER_SUCCESS:
      return {
        ...state,
        viewers: [
          ...state.viewers.filter((item) => item.id !== action.data.id),
          { ...action.data, loaded: true },
        ],
      }

    case DELETE_APP_SUCCESS:
      return {
        ...state,
        viewers: state.viewers.filter(v => v.id !== action.data.id),
      }

    case SET_LAYOUT_NAME:
      return { ...state, layoutName: action.data }

    default:
      return state

  }
}
