import { connect } from "react-redux"
import styles from "./Viewer.module.css"

function AppVariablesComponent(props: {
  appVariables: string
}) {
  return (
    <pre className={styles.appVariables}>
      {JSON.stringify(props.appVariables, undefined, '\n\t').slice(1,-1)}
    </pre>
  )
}

function mapStateToProps(state: any) {
  return { appVariables: state.pubsub }
}

export const AppVariables = connect(mapStateToProps)(AppVariablesComponent)
