import { store, useSelector } from "../store"

export type PublishedDict = Record<string, any>

export type PublishAction = {
  type: PublishType
  data: any
}

export type PublishType = 'PUBLISH' | 'PUBLISH2'

declare global {
  interface Window {
    changes: any
  }
}

export default function handlePubSub(state: PublishedDict = {}, action: PublishAction) {
  switch (action.type) {

    case 'PUBLISH': {
      const timestamp = Date.now()
      const dataWithTimestamps: any = action.data
      for (const n in action.data) dataWithTimestamps[n+"Timestamp"] = String(timestamp);

      if (window.top != null) window.top.changes = dataWithTimestamps; // used by popup button. I would like to make this more generic
      action.data = dataWithTimestamps; // why is wistor not working when using this. so basically why can Wistor not handle multiple parameter changes at once?
      return { ...state, ...action.data }
    }

    case 'PUBLISH2': {
      console.log('publish 2', state, action.data)
      return state
    }

    default: {
      return state
    }

  }
}

export function usePubsub() {
  const pubsub = useSelector(s => s.pubsub ?? {}) as Record<string, unknown>
  return [ pubsub, publish ] as const
}

export function getPubsub() {
  return (store.getState()?.pubsub ?? {}) as Record<string, unknown>
}

export function publish(topic: string, value: unknown) {
  store.dispatch({
    type: "PUBLISH",
    data: { [topic]: value }
  })
}
