import { lazy } from 'react';
import { FontList } from "../../../FontList"
export default class ButtonPopupDescriptor  {

static getDescription() {
    return {
      name: 'BB ButtonPopup',
      component: lazy(() =>  import('./ButtonPopup')),
      label: 'Button Popup',
      variables: [
        {
          name: 'title',
          type: 'text',
          label: 'button label',
        },
        {
          name: 'beginIcon',
          type: 'text',
          label: 'begin icon',
        },
        {
          name: 'endIcon',
          type: 'text',
          label: 'end icon',
        },

        {
          name: 'popupFullScreen',
          type: 'boolean',
          label: 'popup fullScreen not implemented',
        },
        {
          name: 'popupMinWidth',
          type: 'string',
          label: 'popup min width in CSS, e.g. 600px',
        },
        {
          name: 'popupMaxWidth',
          type: 'string',
          label: 'popup max width in CSS, e.g. 100vw for max screen width',
        },
        {
          name: 'popupMinHeight',
          type: 'string',
          label: 'popup min height in CSS, e.g. 600px',
        },
        {
          name: 'popupMaxHeight',
          type: 'string',
          label: 'popup max height in CSS, e.g. 100vh for max screen height',
        },
        {
          name: 'popupContentPadding',
          type: 'string',
          label: 'popup content padding in CSS, e.g. 0px for zero padding',
        },
        {
          name: 'popupCloseOnBackdropClick',
          type: 'boolean',
          label: 'close popup when clicking outside the popup',
        },
        {
          name: 'popupCloseOnEscapeKey',
          type: 'boolean',
          label: 'close popup when pressing Escape',
        },
        {
          name: 'popupTitle',
          type: 'text',
          label: 'popupTitle',
        },

        {
          name: 'tooltip',
          type: 'text',
          label: 'button tooltip',
        },
        {
          name: 'disable',
          type: 'text',
          label: 'disable when parameter is empty/reset value or true' ,
        },
        {
          name: 'autoOpenOnParameter',
          type: 'text',
          label: 'automatically opens popup when the parameter changes.',
        },
        {
          name: 'closeAtParameterChange',
          type: 'text',
          label: 'close popup when the parameter changes. deprecated',
        },
        {
          name: 'openDialog',
          type: 'boolean',
          label: 'open dialog the first time',
        },
        {
          name: 'customStyling',
          type: 'boolean',
          label: 'Enable Custom Styling',
        },
        {
          name: 'buttonBorderRadius',
          type: 'text',
          label: 'Custom Styling: button-border-radius (examples: "2lh", "50%", "1.2em"). See https://developer.mozilla.org/en-US/docs/Web/CSS/border-radius',
        },
        {
          name: 'textColor',
          type: 'text',
          label: 'Custom Styling: text-color (examples: "green", "#FF0000", "hsl(0 100% 50% / 50%)"). See https://developer.mozilla.org/en-US/docs/Web/CSS/color_value',
        },
        {
          name: 'bgColor',
          type: 'text',
          label: 'Custom Styling: background-color (examples: "green", "#FF0000", "hsl(0 100% 50% / 50%)"). See https://developer.mozilla.org/en-US/docs/Web/CSS/color_value',
        },
        {
          name: 'hasButtonShadow',
          type: 'boolean',
          label: 'Custom Styling: enable button-shadow'
        },
        {
          name: 'buttonFontSize',
          type: 'text',
          label: 'Custom Styling: button-font-size'
        },
        {
          name: 'buttonFontFamily',
          type: 'text',
          label: 'Custom Styling: button-font-family',
          helpComponent: FontList
        },
      ],
    }
  }
}
