import { HTMLProps } from "react"

export function PreSpan(props: HTMLProps<HTMLSpanElement>) {
  const style: HTMLProps<HTMLSpanElement>['style'] = {
    ...props.style,
    unicodeBidi: 'embed',
    fontFamily: 'monospace',
    whiteSpace: 'pre',
  }
  return <span {...props} style={style} />
}
