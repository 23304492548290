import './bbutil'
import BBIFrame from './BBIFrame'
import PublishUtils from '../../../helpers/PublishUtils'

class BBRDFViewer extends BBIFrame {
  getUrl = () => `/${window.bbFolderName}/static/iframes/rdfviewer/index.html`

  constructor() {
    super()

    this.scrolling = 'no'
    this.noEvents = false
  }

  static getDescription() {
    return {
      name: 'BBRDFViewer',
      component: BBRDFViewer,
      label: 'BB RDF Viewer',
      variables: [
        {
          name: 'query',
          defaultQuery:"\n\rselect * where\n\r{\n\r\n\r  graph ?g\n\r  {\n\r  \n\r   ?uri a <http://rdf.bg/ontologies/ifc#IfcSpace>.\n\r   ?uri <http://rdf.bg/ontologies/ifc#geometry> ?geometry.\n\r    \n\r   #?uri <http://rdf.bg/ontologies/ifc#propertySets> ?ps.\n\r\n\r  #?ps<http://rdf.bg/ontologies/ifc#properties> ?pss.\n\r  #?pss <http://rdf.bg/ontologies/ifc#name> \"\".\n\r  #?pss <http://rdf.bg/ontologies/ifc#value> ?color.\n\r  ?uri a ?cls.\n\r    bind (strafter(STR(?cls),\"#\") as ?color)\n\r    bind (concat(\"IFC class:\",?color) as ?label)\n\r  #bind (0.3 as ?alpha)\n\r  }\n\r}\n\rlimit 1000",
          type: 'yasgui',
          label: ' Use ?file. or upcoming blob parameters',
        },

        {
          name: 'publishVariable',
          type: 'text',
          label:
            'Variabele in which an item URI is published, when an object is selected.',
        },
        {
          name: 'subscribeVariable',
          type: 'text',
          label:
            'Published uri variabele on which to listen to define selection.',
        },
        PublishUtils.getResetValueDef(),
      ],
    }
  }

  state = { selectedUri: null }
}
export default BBRDFViewer
