//import {GraphQLVoyager} from 'graphql-voyager';
//import '../../../../node_modules/graphql-voyager/dist/voyager.css';
import BBIFrame from './BBIFrame'
import './bbutil'

//var hans =require ('./test.json');

class BBVoyager extends BBIFrame {
  getUrl() {
    BBIFrame.scrolling = 'no'
    return `/${window.bbFolderName}/bbvoyager.html`
  }

  static getDescription() {
    return {
      name: 'BBVoyager',
      component: BBVoyager,
      label: 'UML Diagram Voyager',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label: 'to fill the diagram. ( ?domainName ?rangeName ?propname)',
          defaultQuery: `
                                 PREFIX sh: <http://www.w3.org/ns/shacl#>
                      PREFIX owl: <http://www.w3.org/2002/07/owl#>
        PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
        
        
        select distinct ?domainName ?propName ?rangeName #?selectie  
        
        from  <{{versie}}>
        
        where 
        {
            bind (<{{selection}}> as ?selectie)
                ?cls ^rdfs:subClassOf* ?selectie.
              filter (?cls not in (owl:Thing, rdfs:Resource) )
         #outgoing properties
          {
               ?selectie rdfs:label ?domainName.
              ?cls sh:property ?ps.
              ?ps sh:path ?prop.
                ?prop rdfs:label ?propName.
               # ?prop a owl:DatatypeProperty.
                 {
                 ?ps sh:datatype ?rangeName.
                 }
                 union
                {
                        ?ps sh:class/rdfs:label ?rangeName
                }
        
        }
          
             union
            {
                # objecten die verwijzen naar geselecteerde object
                ?shape sh:property ?ps.
            ?ps sh:class ?cls.
                ?cls rdfs:label ?rangeName.
                ?shape rdfs:label ?domainName.
                ?ps sh:path/rdfs:label ?propName.
        
         }
        
        }
      `
        

 },     
        {
          name: 'height',
          type: 'string',
          label: 'optional css string for the height value',
        },
      ],
    }
  }
}

export default BBVoyager
