import React, { lazy } from "react"

export const dynamicFilterWidgetDescription = {
  name: "DynamicFilter",
  component: lazy(() => import("./DynamicFilter")),
  label: "Dynamic Filter",
  variables: [
    {
      name: 'urlPublishVariable',
      type: 'text',
      label: "Variable in which the browser's URL is published upon filter change."
    },
    {
      name: 'filtersPublishVariable',
      type: 'text',
      label:
        'Variable in which the filters are published. Note that the query will need "PREFIX xsd" in the header.'
    },
    {
      name: 'filterLogicalOperation',
      type: 'select',
      label: 'The behaviour when multiple filters are selected',
      options: [
        { value: 'AND', label: 'Filters connected without UNION (AND)' },
        { value: 'OR', label: 'Filters connected with UNION (OR)' },
      ]
    },
    {
      name: "query",
      type: "yasgui",
      label: "SparQL query to define the possible filters: ?sparqlParameter ?property ?propertyURI ?value ?valueURI ?type ?defaultExpanded ?defaultVisible. Last two parameters are optional.",
      helpComponent: FilterInputQueryHelp
    }
  ]
}

function FilterInputQueryHelp() {
  return (
    <table>
      <tbody>
      <tr>
        <th>
          <code>?sparqlParameter</code>
        </th>
        <td>
          String literal with the name of the SparQL parameter used in the published snippet (<code>?subj</code>). Do not include the <code>?</code>.
        </td>
      </tr>
      <tr>
        <th>
          <code>?property</code>
        </th>
        <td>
          String literal with the label of the filterable property.
        </td>
      </tr>
      <tr>
        <th>
          <code>?propertyURI</code>
        </th>
        <td>
          String literal with the URI of the filterable property.
        </td>
      </tr>
      <tr>
        <th>
          <code>?value</code>
        </th>
        <td>
          For checkboxes, radiobuttons, search: string literal with the label of the filterable values.<br />
          For range or daterange: <code>"min"</code> or <code>"max"</code> to indicate if it's the min or max value.
        </td>
      </tr>
      <tr>
        <th>
          <code>?valueURI</code>
        </th>
        <td>
          For checkboxes, radiobuttons, search: string URI or literal of the filterable values.<br />
          For range: number literal of the min or max value.<br />
          For daterange: date literal of the min or max value like <code>"2023-01-01T00:00:00Z"^^xsd:dateTime</code>.
        </td>
      </tr>
      <tr>
        <th>
          <code>?type</code>
        </th>
        <td>
          Either <code>"checkboxes"</code>, <code>"radiobuttons"</code>, <code>"search"</code>, <code>"range"</code> or <code>"daterange"</code>.
        </td>
      </tr>
      <tr>
        <th>
          <code>?defaultExpanded</code>
        </th>
        <td>
          Whether to expand the filter by default. Either <code>true</code> or <code>false</code>.
        </td>
      </tr>
      <tr>
        <th>
          <code>?defaultVisible</code>
        </th>
        <td>
          Whether the filter is shown by default. Either <code>true</code> or <code>false</code>. If <code>false</code>, it can be added by the user via the Add button.
        </td>
      </tr>
      </tbody>
    </table>
  )
}

