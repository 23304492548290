import React, { Component } from 'react'
import styles from './SplitLayout.module.css'
import Area from '../Area'
import { interpolateSubscriptions } from '../../helpers/widget'
import { mergeClasses } from "../../helpers/styling"

class SplitLayout extends Component {
  static getDescription() {
    return {
      name: 'SplitLayout',
      component: SplitLayout,
      label: 'Split Layout',
      variables: [
        {
          name: 'direction',
          type: 'select',
          label: 'Direction',
          options: [
            {
              label: 'Rijen',
              value: 'column',
            },
            {
              label: 'Kolommen',
              value: 'row',
            },
          ],
        },
        {
          name: 'disableScrollbars',
          type: 'boolean',
          label: 'disable scrollbars',
        },
        {
          name: 'minimize',
          type: 'boolean',
          label: 'minimize size',
        },
        {
          name: 'areaSizes',
          type: 'array',
          // label: 'Column widths or row heights (separated by commas). Value can be a number (which will be set as flex values/verhoudingswaarden) or a px/% value',
          label:
            "Kolom Breedte of Rij hoogte, gescheiden door comma's. Waarden kunnen gegeven worden in pixels, percentage of getallen. Een getal is verhoudingsgetal ten opzichte van de andere Rijen/Kolommen. (Een voorbeeld: 130px,1,10%)",
        },
        {
          name: 'style',
          type: 'json',
          label: 'style for each area',
        },
        {
          name: 'areas',
          type: 'json',
          // label: 'Column widths or row heights (separated by commas). Value can be a number (which will be set as flex values/verhoudingswaarden) or a px/% value',
          label: 'area definitions',
        },
      ],
    }
  }

  render() {
   
    if (this.props.withinSelectedTab === false && this.firstTime == null) {
      return null
    } else {
      this.firstTime = false
    }

    let areaSizes = this.props.areaSizes
    let areas= this.props.areas;

    if (areas==null)areas=[];

    if (typeof areaSizes === 'string') {
      areaSizes = areaSizes.split(' ')
    }

    const extraClassNames = mergeClasses({
      [styles.overflowHidden]: this.props.disableScrollbars
    })

    let mode = this.props.mode || 'preview'

    // if (this.props.minimize)
    return (
      <div
        className={
          styles.main +
          ' ' +
          styles[this.props.direction] +
          ' ' +
          styles[mode] +
          ' ' +
          extraClassNames
        }
      >
        {areaSizes.map((areaSize, index) => {
          let areaConfig = areas[index] || {}
          areaSize = areaSize.trim()
          let flex = '0 0 auto'
          // let flex = '1 1 auto';
          let fixedSize = 'auto'

          if (/^\d+$/.test(areaSize)) {
            if (areaSize === '0') {
              areaSize = '0 0 auto'
            }
            flex = areaSize
          } else {
            fixedSize = areaSize
          }
          let heightOrWidth =
            this.props.direction === 'row' ? 'width' : 'height'
          if (this.props.minimize) {
            heightOrWidth =
              'max' +
              heightOrWidth.substr(1).toLocaleUpperCase() +
              heightOrWidth.substr(1)
            //console.log("minimize ",heightOrWidth, fixedSize);
            flex = '1 1 auto'
          }

          if (this.props.direction !== 'row') {
            // console.log(areaSize);
            if (!areaSize.endsWith('px')) {
              flex = '1 1 auto'
            }
          }
          if (mode !== 'preview' && areaSize.endsWith('px')) {
            areaSize = parseInt(areaSize.replace('px', ''))
            areaSize += 200
            areaSize = areaSize + 'px'
            // console.log("moeten we nu niet de hoogte aanpassen ?",areaSize);
          }

          if (interpolateSubscriptions(this, areaConfig.hidden, 'boolean'))
            fixedSize = (() => {
              if (this.props.mode !== 'edit') return 0
              if (this.props.direction === 'row') return 100
              return 20
          })()

          return (
            <div
              key={index}
              className={mergeClasses(styles.areaContainer, extraClassNames)}
              style={{
                flex,
                [heightOrWidth]: fixedSize,
                ...(this.props.style?.length ? this.props.style[index] : {})
              }}
            >
              <Area
                {...this.props}
                disableFlexbox={false}
                index={index}
                config={areaConfig}
                onChange={this.props.onChange}
              />
            </div>
          )
        })}
      </div>
    )
  }
}

export default SplitLayout
