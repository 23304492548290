import style from "./Button.module.css"
import clsx from "clsx"

export type ButtonVariant = "primary" | "info" | "outline"

export function Button(props: {
  variant: ButtonVariant
  className?: string
  children?: React.ReactNode
  type?: "button" | "submit"
} & Omit<React.HTMLProps<HTMLButtonElement>, "type">) {
  const { variant, className, children, type, ...otherProps } = props
  return (
    <button type={props.type ?? "button"}
            className={clsx(style["button"], style[props.variant], props.className)}
            {...otherProps}>
      {props.children}
    </button>
  )
}

export function ButtonLink(props: {
  variant: ButtonVariant
  className?: string
  children?: React.ReactNode
} & React.HTMLProps<HTMLAnchorElement>) {
  const { variant, className, children, ...otherProps } = props
  return (
    <a className={clsx(style["button"], style[props.variant], props.className)}
       {...otherProps}>
      {props.children}
    </a>
  )
}