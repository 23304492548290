import { Suspense, ReactNode } from 'react'
import Spinner from './Spinner'
import css from './Loader.module.css'

type Props = {
  active: boolean
  children?: ReactNode | undefined
}

export function Loader(props: Props) {
  return (
    <Suspense fallback={
      <LoadingOverlay active={props.active}></LoadingOverlay>
    }>
      <LoadingOverlay active={props.active}>
        {props.children}
      </LoadingOverlay>
    </Suspense>
  )
}

function LoadingOverlay(props: Props) {
  return (
    <>
      {props.active && (
        <div className={css['overlay']}>
          <Spinner loading={props.active} />
        </div>
      )}
      {props.children}
    </>
  )
}
