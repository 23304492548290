import React, { Component } from 'react'
import styles from './Slider.module.css'
import { restructureResponse } from '../../helpers/sparql'
import throttle from 'lodash/throttle'

class Slider extends Component {
  state = {}
  sliderTouched = false

  static getDescription() {
    var r = {
      name: 'Slider',
      component: Slider,
      label: 'Slider widget',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            'Sparql query to define min max values of the slider. it uses only the first parameter',
        },
        {
          name: 'publishVariable',
          type: 'text',
          label: 'Variabele in which the value of the slider is published.',
        },

        {
          name: 'startMin',
          type: 'boolean',
          label: 'default on minimum',
        },
        {
          name: 'publishStartValue',
          type: 'boolean',
          label: 'publish the initial value',
        },

        {
          name: 'tick',
          type: 'text',
          label: 'tick',
        },
        {
          name: 'label',
          type: 'text',
          label: 'Label',
        },
        {
          name: 'textwidth',
          type: 'text',
          label: 'text width',
        },
      ],
    }
    return r
  }

  componentDidUpdate(prevProps) {
    const newRange = this.defineMaxMinValue()
    const oldRange = this.defineMaxMinValue(prevProps)
    if (newRange.maxValue === oldRange.maxValue && newRange.minValue === oldRange.minValue) return

    const value = this.sliderTouched ? Math.max(newRange.minValue, Math.min(newRange.maxValue, this.state.value)) :
                this.props.startMin ? newRange.minValue : newRange.maxValue
    this.setState({value: value})
    if (this.props.publishVariable && this.props.publishStartValue) {
      this.props.publish(this.props.publishVariable, value)
    }
  }

  defineMaxMinValue(alternateProps) {
    let minValue = 1000000
    let maxValue = -1000000
    try {
      var data = this.props.data
      if (alternateProps) {
        data = alternateProps.data
      }
      let headVars = data.head.vars
      let key = headVars[0] // eg. oprichtingsDatum
      let items = restructureResponse(data)

      items.forEach((item) => {
        let value = item[key]
        try {
          if (typeof value === 'string') value = Number(value)
        } catch (error) {
          console.log(error)
        }
        if (value < minValue) minValue = value
        if (value > maxValue) maxValue = value
      })
      return { minValue, maxValue }
    } catch (e) {
      console.log(e)
    }
    return { minValue, maxValue }
  }

  onChange = (event) => {
    this.sliderTouched = true
    this.setState({
      value: event.target.value,
    })
    // console.log(event);

    //this.onChangeThrottle();
  }
  onmouseup = (event) => {
    // console.log("mouse up");
    this.onChangeThrottle()
  }

  onChangeThrottle = throttle(() => {
    // console.log("slider update");
    let value = this.state.value

    if (value) {
      console.log('really publishing ', this.props.publishVariable, value)
      this.props.publish(this.props.publishVariable, value)
    }
  }, 200)

  render() {
    if (!this.props.data) return null
    let step = 1
    if (this.props.tick != null) {
      try {
        step = parseInt(this.props.tick)
      } catch (error) {}
    }
    let { minValue, maxValue } = this.defineMaxMinValue()

    let value
    if (this.props.startMin) {
      value = this.state.value || minValue
    } else {
      value = this.state.value || maxValue
    }

    return (
      <div className={styles.main}>
        <table>
          <tbody>
            <tr>
              <td>
                <div className={styles.label}>{this.props.label}</div>
                <div className={styles.container}>
                  <div className={styles.rangeValue}>{minValue}</div>
                  <input
                    className={styles.slider}
                    type="range"
                    min={minValue}
                    max={maxValue}
                    value={value}
                    step={step}
                    onChange={this.onChange}
                    onMouseUp={this.onmouseup}
                  />
                  <div className={styles.rangeValue}>{maxValue}</div>
                  <div className={styles.rangeValue}>({value})</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

export default Slider
