import { resolveTemplateString } from "./resolve-template-string";
import { runStartRule } from "./run-start-rule"

class PublishUtils {
  static resetUri="http://www.buildingbits.nl/reset";
  
  static setTimeStampParameter(widget: {
    props: {
      pubsub: Record<string, unknown>
      publish: (topic: string, value: unknown) => void
      timestampParameters: string | null | undefined
    }
  }, debug: boolean = false)
  {
    if (debug) {
      console.log(
        'timestamp parameter called ',
        widget.props.timestampParameters
      )
    }
    if (widget.props.timestampParameters) {
      var valuesRaw = widget.props.timestampParameters
      var values = valuesRaw.split(',')
      var me = widget
      var value = 'time:' + Date.now()
      if (debug) console.log(values, value)

      if (values.length > 0) {
        if (debug) console.log(values[0],widget.props.pubsub[values[0]])
        if (
         // widget.props.pubsub[values[0]] != null &&
          widget.props.pubsub[values[0]] !== value
        ) {
          //console.log(widget);
          setTimeout(function () {
           // console.log('resetting parameter 1 ' + values[0], value)
            widget.props.publish(values[0], value)
          }, 25)
        }
        //   data[values[n]]="http://www.buildingbits.nl/reset";
      }
      if (values.length > 1) {
        if (
          widget.props.pubsub[values[1]] != null &&
          widget.props.pubsub[values[1]] !== value
        ) {
          setTimeout(function () {
            console.log('resetting parameter 2 ' + values[1])
            me.props.publish(values[1], value)
          }, 50)
        }
      }
      if (values.length > 2) {
        if (
          widget.props.pubsub[values[2]] != null &&
          widget.props.pubsub[values[2]] !== value
        ) {
          setTimeout(function () {
            console.log('resetting parameter 3 ' + values[2])
            me.props.publish(values[2], value)
          }, 75)
        }
      }
      if (values.length > 3) {
        if (
          widget.props.pubsub[values[3]] != null &&
          widget.props.pubsub[values[3]] !== value
        ) {
          setTimeout(function () {
            console.log('resetting parameter 4 ' + values[3])
            me.props.publish(values[3], value)
          }, 100)
        }
      }
      if (values.length > 4) {
        if (
          widget.props.pubsub[values[4]] != null &&
          widget.props.pubsub[values[4]] !== value
        ) {
          setTimeout(function () {
            console.log('resetting parameter 5 ' + values[4])
            me.props.publish(values[4], value)
          }, 125)
        }
      }
      if (values.length > 5) {
        console.log('too many properties to reset')
        alert('too many properties to reset in search box')
      }

      if (debug) {
        console.log('reset parameter called for ', values)
      }
    }
  }

  static publish(props: {
    dispatch: (value: unknown) => void
  }, key: string, value: unknown) {
    var data = { key: value };

    props.dispatch({
      type: "PUBLISH",
      data: data
    });
  }




  static getResetValueDef() {
    return {
      name: 'resetValues',
      type: 'text',
      label:
        'reset this parameter after select event. multiple parameters via comma seperation',
    }
  }
  static getRunRuleParametersDef()
  {
    return {
      name: 'ruleTagAfterSubmit',
      type: 'text',
      label: 'run rule with tag after succesful submit',
    };
  }

  static getRunRuleOptionalParametersDef()
  {
    return {
      name: 'ruleparameters',
      type: 'json',
      label: 'extra json values',
    };
  }


  

  static async runRule(widget:any,json:any)
  {   
    //console.log("run rule json",json);
    const parsedObject: Record<string, string> = {};

    for (const key in json) {
        if (typeof json[key] === 'string') {
            parsedObject[key] = json[key];
        } else {
            console.log(`Invalid value for key ${key}: expected a string.`);
        }
    }
   // console.log(parsedObject,json)

    const rule =widget.props.ruleTagAfterSubmit
    if (!rule) return;
    
    await runStartRule(rule, widget.props.parameters,parsedObject)
  }
  

  static getTimestampParametersDef() {
    return {
      name: 'timestampParameters',
      type: 'text',
      label:
        'sets a timestamp after main event. multiple parameters via comma seperation',
    }
  }

  static async resetParameter(widget: {
    props: {
      pubsub: Record<string, unknown>
      publish: (topic: string, value: unknown) => void
      resetValues: string | null | undefined
    }
  }, debug = false): Promise<unknown> {
    if (debug) {
      console.log('reset parameter called', widget.props.resetValues)
    }
    if (!widget.props.resetValues) return

    const resetParametersRaw = widget.props.resetValues
    const resetParameters = resetParametersRaw.split(',')
    const resetValue = 'http://www.buildingbits.nl/reset'

    const promises = []
    for (const resetParameter of resetParameters) {
      const currentValue = widget.props.pubsub[resetParameter]
      if (currentValue == null || currentValue === resetValue) continue
      promises.push(setTimeoutAsync(() => {
        console.log(`resetting parameter: ${resetParameter}`)
        widget.props.publish(resetParameter, resetValue)
      }, 25))
    }

    return Promise.all(promises)
  }
  static removeUnresolvedParametersFromString(value:string|"")
  {
    return value.replace(/{{!?[A-Za-z0-9|:./]*}}/g,"")
  }


  static processStringForParameters(widget: {
    props: {
      pubsub: Record<string, unknown>
    }
  }, strTemplate: string | null | undefined) {
    if (!strTemplate) return ""

    try {
      return resolveTemplateString(strTemplate, widget.props.pubsub)
    } catch(e) {
      console.log(`${widget}:\n\n${e}\n\n`)
      return strTemplate
    }
  }

  static emptyDataParameterChange(widget: {
    props: {
      pubsub: Record<string, unknown>
      resetDataProps: string | null | undefined
    }
  }, arg: any, arg2: any) {
    if (widget.props.resetDataProps) {
      if (arg.data != null) {
        console.log(widget.props.pubsub.button, arg.pubsub.button)
        console.log(widget, arg, arg2)
      }
    }
  }

  static getEmptyDataParameterChange() {
    return {
      name: 'resetDataProps',
      type: 'text',
      label:
        'reset data value when this parameter changes. multiple parameters via comma seperation',
    } as const
  }

  /**
   * Converts a list of values to a single value string according to multipleselectparametertype
   */
  static getListAsSingleValue(multipleselectparametertype: "csvuri" | "csvstring" | "valuestring" | "valueuri" | null | undefined, list: Array<string | number>) {
    const record: Record<string, boolean> = {}
    list.forEach(item => record[item] = true)
    return PublishUtils.getMultiplePropValueAsSingleValue(multipleselectparametertype, record)
  }

  /**
   * Converts a record holding multiple values to a single value string according to multipleselectparametertype
   */
  static getMultiplePropValueAsSingleValue(multipleselectparametertype: "csvuri" | "csvstring" | "valuestring" | "valueuri" | null | undefined, values: Record<string, boolean>)
  {
    let valueString = ''
      let komma = ''
      let pre = '<'
      let end = '>'
      let kommaT = ','
      if (multipleselectparametertype === 'csvstring') {
        pre = '"'
        end = pre
      }
      if (multipleselectparametertype === 'valuestring') {
        pre = '("'
        end = '") '
        kommaT = ''
      }
      if (multipleselectparametertype === 'valueuri') {
        pre = '(<'
        end = '>) '
        kommaT = ''
      }
    if (values==null)
    {
      return this.getEmptyMultiplePropValueAsSingleValue(multipleselectparametertype);
    }
      for (let n in values) {
        if (values[n]) {
          valueString += komma + pre + n + end
          komma = kommaT
        }
      }
      //  console.log(valueString);
      if (valueString === '') valueString=this.getEmptyMultiplePropValueAsSingleValue(multipleselectparametertype);
      return valueString;
  }

  /**
   * Parses multiple values from multiplePropValue according to multipleSelectParameterType
   */
  static parseMultiplePropValue(multipleSelectParameterType: "csvuri" | "csvstring" | "valuestring" | "valueuri" | null | undefined, multiplePropValue: string) {

    const escapeChar = '\\'

    const formatDict = {
      csvuri: { prefix: '<', postfix: '>', separator: ',' },
      csvstring: { prefix: '"', postfix: '"', separator: ',' },
      valuestring: { prefix: '("', postfix: '")', separator: ' ' },
      valueuri: { prefix: '(<', postfix: '>)', separator: ' ' },
    }

    const { prefix, postfix, separator } = formatDict[multipleSelectParameterType ?? "csvuri"]

    const after = (lookingFor: 'prefix' | 'value' | 'postfix' | 'separator') => {
      if (lookingFor === 'prefix') return 'value'
      if (lookingFor === 'value' && postfix === '') return 'separator'
      if (lookingFor === 'value') return 'postfix'
      if (lookingFor === 'postfix' && separator === '') return 'prefix'
      if (lookingFor === 'postfix') return 'separator'
      if (lookingFor === 'separator' && prefix === '') return 'value'
      if (lookingFor === 'separator') return 'prefix'
      throw new Error(`unknown value for "lookingFor": ${lookingFor}`)
    }

    /**
     * parses char as part of symbol and pushes that onto parsed
     */
    const parseSymbolIntoParsed = (parsed: string, char: string, symbol: string) => {
        const expectedChar = symbol[parsed.length]
        if (char !== expectedChar) {
          console.log({char, expectedChar, symbol, parsedLength: parsed.length})
          throw new Error(`${char} was expected to be part of ${symbol} as ${multipleSelectParameterType} in ${multiplePropValue}`)
        }
        parsed += char
        return parsed
    }

    type ParserState = {
      currentEscapeChar?: string
      values: string[]
      parsed: string
      lookingFor: 'prefix' | 'value' | 'postfix' | 'separator'
    }

    const initialParserState: ParserState = { values: [], parsed: '', lookingFor: after('separator') }
    const { values } = Array.from(multiplePropValue).reduce((currentState: ParserState, char): ParserState => {
      let { currentEscapeChar, values, parsed, lookingFor } = currentState
      try {

        if (lookingFor === 'prefix') {
          parsed = parseSymbolIntoParsed(parsed, char, prefix)
          if (parsed.length === prefix.length) return { values, parsed: '', lookingFor: after('prefix') }
          return { values, parsed, lookingFor }
        }

        if (lookingFor === 'value') {
          if (!currentEscapeChar && char === escapeChar)
            return { currentEscapeChar: char, values, parsed, lookingFor }

          if (currentEscapeChar || char !== postfix[0]) {
            parsed += char
            return { values, parsed, lookingFor }
          }

          values.push(parsed)
          parsed = ''
          lookingFor = after('value')
        }

        if (lookingFor === 'postfix') {
          parsed = parseSymbolIntoParsed(parsed, char, postfix)
          if (parsed.length === postfix.length) return { values, parsed: '', lookingFor: after('postfix') }
          return { values, parsed, lookingFor }
        }

        if (lookingFor === 'separator') {
          parsed = parseSymbolIntoParsed(parsed, char, separator)
          if (parsed.length === separator.length) return { values, parsed: '', lookingFor: after('separator') }
          return { values, parsed, lookingFor }
        }

      } catch(e) {
        // @ts-ignore
        const errorMessage = e.message
        throw new Error(errorMessage + '\n\n' + JSON.stringify({ values, parsed, lookingFor, char }))
      }

      throw new Error(`unknown value for "lookingFor": ${lookingFor}`)
    }, initialParserState)

    return values
  }

  static getEmptyMultiplePropValueAsSingleValue(multipleselectparametertype: "csvuri" | "csvstring" | "valuestring" | "valueuri" | null | undefined)
  {
    let valueString = ''
     let value='';
      let pre = '<'
      let end = '>'
      let kommaT = ','
      if (multipleselectparametertype === 'csvstring') {
        pre = '"'
        end = pre
      }
      if (multipleselectparametertype === 'valuestring') {
        pre = '("'
        end = '") '
        kommaT = ''
      }
      if (multipleselectparametertype === 'valueuri') {
        pre = '(<'
        end = '>) '
        kommaT = ''
        value='http://www.buildingbits.nl/reset';
      }

              
          valueString = pre + value + end
         
      
      //  console.log(valueString);
    //  if (valueString === '') valueString = '<http://www.buildingbits.nl/reset>';
      return valueString;
  }

  static getMultipleValueFormatOptions({
    name = 'multipleselectparametertype',
    label = 'for Multiple selection only: how to set the multivalue parameters'
  }: {
    name?: string
    label?: string
  } = {}) {
    return {
      name,
      type: 'select',
      options: [
        { label: 'csv as uri', value: 'csvuri' },
        { label: 'csv as string with "', value: 'csvstring' },
        { label: 'sparql value string with "', value: 'valuestring' },
        { label: 'sparql value uri', value: 'valueuri' }
      ],
      label
    } as const
  }
}

function setTimeoutAsync<T>(callback: () => T, delay: number): Promise<T> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        resolve(callback())
      } catch (e) {
        reject(e)
      }
    }, delay)
  })
}

export default PublishUtils
