import { LoginState } from "../redux/login"
import { connect } from "react-redux"
import { ReactNode } from "react"
import { Route } from "react-router-dom"
import { RouteProps, useRouteMatch, match } from "react-router"
import { Login } from "./login/Login"


function ProtectedRouteComponent(props: {
  path: string,
  loginState: LoginState,
  children: ReactNode,
  protectIf?: (match: match<{}> | null) => boolean
} & RouteProps) {
  const { loginState, children, component, ...rest } = props
  const match = useRouteMatch(props.path)

  const isAuthorized = props.protectIf
    ? (props.protectIf(match) ? loginState.isLoggedIn : true)
    : loginState.isLoggedIn

  return (
    <Route
      {...rest}
      component={isAuthorized ? component : Login}
    />
  )
}


function mapStateToProps(state: any) {
  return { loginState: state.login }
}

export const ProtectedRoute = connect(mapStateToProps)(ProtectedRouteComponent)
