import './bbutil'
import BBIFrame from './BBIFrame'

class BBYasguiNotebook extends BBIFrame {
  getUrl() {
    BBIFrame.scrolling = 'yes'
    return `/${window.bbFolderName}/bbyasguinotebook.html`
  }
}

export default BBYasguiNotebook
