import BBIFrame from './BBIFrame'
import PublishUtils from './../../../helpers/PublishUtils'

export default class BBVis extends BBIFrame {
  getUrl = () => `/${window.bbFolderName}/w2ui/visgraph.html`

  constructor() {
    super()

    this.scrolling = 'false'
    this.noEvents = false
    this.usesParameters=false;
  }

  static getDescription() {
    return {
      name: 'BBVis',
      component: BBVis,
      label: 'graph vis',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          defaultQuery:"PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>\n\rPREFIX skos: <http://www.w3.org/2004/02/skos/core#>\n\rPREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>\n\r\n\rselect distinct *\n\r\n\rwhere\n\r{\n\r  \n\r  bind ( <{{selection}}>  as ?node)\n\r    \n\r    optional {?node rdfs:label|skos:prefLabel ?nodeLabel2.}\n\r\n\r bind (STRBEFORE(STR(?node),\"#\") as ?btext)\n\r bind (IF(?btext=\"\",REPLACE(STR(?node),'([^/]+$)',\"\"),?btext) as ?nodeType)\n\r    bind (IF(BOUND(?nodeLabel2),?nodeLabel2, ?nodeType) as ?nodeLabel)\n\r\n\r\n\r    ?node ?edge ?object.\n\r\n\r  filter (!ISBLANK(?object))\n\r  filter(!ISLITERAL(?object))\n\r\n\r\n\rbind (STRBEFORE(STR(?object),\"#\") as ?botext)\n\r bind (IF(?botext=\"\",REPLACE(STR(?object),'([^/]+$)',\"\"),?botext) as ?objectType)\n\r\n\r   \n\r  bind (REPLACE(REPLACE(REPLACE(REPLACE(STR(?edge),\"http://www.w3.org/2000/01/rdf-schema#\",\"rdfs:\"),\"http://www.w3.org/1999/02/22-rdf-syntax-ns#\",\"rdf:\"),\"http://www.w3.org/2004/02/skos/core#\",\"skos:\"),\"http://www.rws.nl/abdl#\",\"abdl:\") as ?edgeLabel)\n\r    optional {?object rdfs:label|skos:prefLabel ?olabel. } \n\r    bind (if (BOUND(?olabel),?olabel, ?object) as ?objectLabel2)\n\r\n\r bind (REPLACE(REPLACE(REPLACE(REPLACE(REPLACE(STR(?objectLabel2),\"http://www.w3.org/2000/01/rdf-schema#\",\"rdfs:\"),\"http://www.w3.org/1999/02/22-rdf-syntax-ns#\",\"rdf:\"),\"http://www.w3.org/2004/02/skos/core#\",\"skos:\"),\"http://www.rws.nl/abdl#\",\"abdl:\"),\"https://bimloket.nl/data/kernmodel/thesaurus#\",\"cbnl:\") as ?objectLabel)\n\r\n\r\n\r\n\r}\n\r",
          label:
            'to fill the graph. Use node nodeLabel edge edgeLabel object objectLabel. optional hier (true false) for hierarchical rep. shape/shapeO (e.g circle) for node shape. objectType & nodeType voor kleur/groepering ',
        },
        {
          name: 'extendquery',
          type: 'yasgui',
          label: '  {{uri}} and Use  edge edgeLabel object objectLabel',
          defaultQuery:`PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>

          PREFIX skos: <http://www.w3.org/2004/02/skos/core#>
          
          PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
          
          
          select distinct *
          
          
          where
          
          {
          
            
          
            bind ( <{{uri}}>  as ?node)
          
              
          
              optional {?node rdfs:label|skos:prefLabel ?nodeLabel2.}
          
          
           bind (STRBEFORE(STR(?node),"#") as ?btext)
          
           bind (IF(?btext="",REPLACE(STR(?node),'([^/]+$)',""),?btext) as ?nodeType)
          
              bind (IF(BOUND(?nodeLabel2),?nodeLabel2, ?nodeType) as ?nodeLabel)
          
          
          
              ?node ?edge ?object.
          
          
            filter (!ISBLANK(?object))
          
            filter(!ISLITERAL(?object))
          
          
          
          bind (STRBEFORE(STR(?object),"#") as ?botext)
          
           bind (IF(?botext="",REPLACE(STR(?object),'([^/]+$)',""),?botext) as ?objectType)
          
          
             
          
            bind (REPLACE(REPLACE(REPLACE(REPLACE(STR(?edge),"http://www.w3.org/2000/01/rdf-schema#","rdfs:"),"http://www.w3.org/1999/02/22-rdf-syntax-ns#","rdf:"),"http://www.w3.org/2004/02/skos/core#","skos:"),"http://www.rws.nl/abdl#","abdl:") as ?edgeLabel)
          
              optional {?object rdfs:label|skos:prefLabel ?olabel. } 
          
              bind (if (BOUND(?olabel),?olabel, ?object) as ?objectLabel2)
          
          
           bind (REPLACE(REPLACE(REPLACE(REPLACE(REPLACE(STR(?objectLabel2),"http://www.w3.org/2000/01/rdf-schema#","rdfs:"),"http://www.w3.org/1999/02/22-rdf-syntax-ns#","rdf:"),"http://www.w3.org/2004/02/skos/core#","skos:"),"http://www.rws.nl/abdl#","abdl:"),"https://bimloket.nl/data/kernmodel/thesaurus#","cbnl:") as ?objectLabel)
          
          
          
          
          }
          
          `
        },
        {
          name: 'edgelabels',
          type: 'boolean',
          label: 'show edge labels using edgeLabel',
        },
        {
          name: 'useLocalNames',
          type: 'boolean',
          label: 'use local names for labels',
        },

        {
          name: 'legend',
          type: 'boolean',
          label: 'show legend based upon parameters objectType and nodeType',
        },

        {
          name: 'publishVariable',
          type: 'text',
          label:
            'Variabele in which an item URI is published, when a feature in the map is clicked.',
        },
        {
          name: 'subscribeVariable',
          type: 'text',
          label:
            'Published uri variabele on which to listen to define selection.',
        },
        {
          name: 'direction',
          type: 'select',
          label: 'direction layout',
          options: [
            {
              label: 'Vertical starting from top',
              value: 'UD',
            }, //UD, DU, LR, RL
            {
              label: 'Vertical starting from bottom',
              value: 'DU',
            }, //UD, DU, LR, RL
            {
              label: 'Horizontal starting from left',
              value: 'LR',
            }, //UD, DU, LR, RL
            {
              label: 'Horizontal starting from right',
              value: 'RL',
            },
          ],
        },
        {
          name: 'edgetype',
          type: 'select',
          label: 'edge type',
          options: [
            { label: 'none', value: null },
            {
              label: 'to',
              value: 'to',
            }, //UD, DU, LR, RL
            {
              label: 'from',
              value: 'from',
            }, //UD, DU, LR, RL
            {
              label: 'middel',
              value: 'middle',
            }, //UD, DU, LR, RL
            {
              label: 'to and from',
              value: 'to;from',
            },
          ],
        },
        {
          name: 'hierarchical',
          type: 'boolean',
          label: 'hierarchical',
        },

        {
          name: 'dragNodes',
          type: 'boolean',
          label: 'drag nodes',
        },
        {
          name: 'editable',
          type: 'boolean',
          label: 'editable',
        },
        PublishUtils.getResetValueDef(),
        {
          name: 'className',
          type: 'text',
          label: 'iframe classNames',
        },
      ],
    }
  }
}
