import { getCgiUrl } from "../helpers/url"
import axios, { AxiosError } from "axios"
import { z } from "zod"
import { RepoAccessUser } from "../model/repo-access-user"

export type RepoLoginCredentials = {
  username: string
  password: string
}

type RepoLoginSuccess = {
  success: true
  user: RepoAccessUser
}

type RepoLoginFailure = {
  success: false
  reason: string
}

const RepoLoginResponseSchema = z.object({
  loggedIn: z.string(), // boolean-string with casing variations...
  repo: z.string().optional(),
  parameters: z.record(z.string(), z.any()).optional(),
  message: z.string().optional()
})

export async function repoLogin(loginParams: RepoLoginCredentials, repoName: string, repoUrl: string): Promise<RepoLoginSuccess | RepoLoginFailure> {
  const url = getCgiUrl('login', repoName, repoUrl)

  try {
    const response = await axios.post(url, {
      login: loginParams.username,
      psw: loginParams.password
    }, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    })
  

    const repoLoginResponse = RepoLoginResponseSchema.parse(response.data)
    if (repoLoginResponse.loggedIn.toLowerCase() !== "true") {
      return { success: false, reason: repoLoginResponse.message ?? 'Incorrect username or password' }
    }

    if (repoLoginResponse.repo !== repoName) {
      return { success: false, reason: 'This account is not valid for this app' }
    }

    if (repoLoginResponse.parameters) {
      // @ts-ignore
      document.userParameters = repoLoginResponse.parameters
    }
   
    return {
      success: true,
      user: {
        repo: repoLoginResponse.repo,
        userParameters: repoLoginResponse.parameters ?? {}
      }
    }
  } catch (error) {
    if (!(error instanceof AxiosError)) {
      throw error
    }
    
    console.error(error)
    return { success: false, reason: 'Request failure' }
  }
}

