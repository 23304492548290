import { useEffect, useState } from 'react'
import Yasqe from '@triply/yasqe'
import '../../node_modules/@triply/yasgui/build/yasgui.min.css'
import '../../node_modules/@triply/yasqe/build/yasqe.min.css'
import { store } from '../store'

export function SparqlEditor(props: {
  value: string
  onBlur: (value: string) => void
  mode: 'update-value' | 'init-value'
}) {
  const [yas, setYas] = useState<Yasqe | null>(null)
  const [ref, setRef] = useState<any>(null)

  useEffect(() => {
    if (!ref) return () => {}

    const newYas = new Yasqe(ref)
    setYas(newYas)
    store.dispatch({type: 'ADD_YASQE', data: newYas})
    return () => store.dispatch({type: 'REMOVE_YASQE', data: newYas})
  }, [ref])

  const {onBlur, value} = props
  useEffect(() => {
    if (!yas) return
    yas.setValue(value || '')
    yas.on('blur', () => onBlur(yas.getValue()))
  }, [yas, onBlur, value])

  useEffect(() => {
    if (!yas || props.mode === 'init-value') return
    yas.setValue(props.value)
  }, [yas, props.value, props.mode])

  return <div ref={setRef} />
}
