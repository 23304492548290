/**
 * Helper class to pre-create references of Promises while allowing the resolve function to be specified at a later time.
 *
 * This is useful when you need the promise to be assigned somewhere whilst constructing it.
 */
export class DeferredPromise<T> implements PromiseLike<T> {
  promise: Promise<T>

  resolve: (value: (PromiseLike<T> | T)) => void
  reject: (reason?: any) => void

  catch: <TResult = never>(onrejected?: ((reason: any) => (PromiseLike<TResult> | TResult)) | undefined | null) => Promise<T | TResult>
  then: <TResult1 = T, TResult2 = never>(onfulfilled?: ((value: T) => (PromiseLike<TResult1> | TResult1)) | undefined | null, onrejected?: ((reason: any) => (PromiseLike<TResult2> | TResult2)) | undefined | null) => Promise<TResult1 | TResult2>

  readonly [Symbol.toStringTag] = "Promise"

  constructor() {
    this[Symbol.toStringTag] = "Promise"

    // make ts happy
    this.resolve = () => {}
    this.reject = () => {}

    this.promise = new Promise((resolve, reject) => {
      // assign the resolve and reject functions to `this`
      // making them usable on the class instance
      this.resolve = resolve
      this.reject = reject
    })

    // bind `then` and `catch` to implement the same interface as Promise
    this.then = this.promise.then.bind(this.promise)
    this.catch = this.promise.catch.bind(this.promise)
  }
}
