
import { lazy } from 'react';
import PublishUtils from '../../../helpers/PublishUtils';

class BBPropertyPanelDescriptor {
  

  static getDescription() {
    return {
      name: 'BBPropertyPanel',
      component: lazy(() =>  import('./BBPropertyPanel')),
      label: 'BBPropertyPanel',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          defaultQuery:"PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#> \n\r \n\r select ?property ?pred ?value ?literal ?beschrijving ?beschrijvingValue ?icon ?iconColor\n\rwhere\n\r{\n\r  <{{selection}}> ?pred ?value.\n\r  filter(?pred!=rdf:type).\n\r  filter (?pred!=<http://www.opengis.net/ont/geosparql#asWKT>)\n\r  bind (strafter(StR(?pred),\"#\") as ?property)\n\r  bind (ISLITERAL(?value) as ?literal)\n\r  bind (\"fa fa-star\" as ?icon)\n\r  bind (\"#aa00aa\" as ?iconColor)\n\r  bind (\"een beschrijving\" as ?beschrijving)\n\r # bind (\"een beschrijving 2\" as ?beschrijvingValue)\n\r  \n\r  \n\r   \n\r}",
          label:
            'to fill the property panel. Use ?property and ?value. optionally use  ?beschrijving  ?beschrijvingValue ?literal ?pred  ?iconColor ?icon',
        },
        {
          name: 'childParameterText',
          type: 'text',
          label:
            'string to replace in sparql with uri for getting child info (e.g. {{uri}})',
        },
        {
          name: 'renderEmpty',
          type: 'boolean',
          label: 'render an empty table',
        },
        {
          name: 'subscribeVariable',
          type: 'text',
          label:
            'Published uri variabele on which to listen to define selection.',
        },
        {
          name: 'editable',
          type: 'boolean',
          label:
            'allow edits. this needs extra ?graph ?datatype ?uri parameters',
        },
        {
          name: 'ruleTagAfterSubmit',
          type: 'text',
          label: 'run rule with tag after succesful submit',
        },
        {
          name: 'parameters',
          type: 'json-text',
          label:
            'extra parameters send in JSON format,Parameters will be resolved before sending. keep it simple. no subchildren',
        },
        
        PublishUtils.getTimestampParametersDef(),
        {
          name: 'failureMessage',
          type: 'text',
          label: 'failure message',
        },
        {
          name: 'className',
          type: 'text',
          label: 'iframe classNames',
        },
      ],
    }
  }

  
}

export default BBPropertyPanelDescriptor
