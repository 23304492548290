import { Dispatch } from 'redux'
import {DELETE_APP_SUCCESS} from '../redux/project'
import axios from 'axios'
import { getBearerToken } from './utils'

type DispatchAppConfigDeletion = Dispatch<{
  type: typeof DELETE_APP_SUCCESS
  data: { id: string }
}>

export async function deleteAppConfig(
  id: string,
  dispatch: DispatchAppConfigDeletion
) {
  try {

    await axios({
      method: 'post',
      url: '/api/delete-project',
      data: { id },
      headers: { Authorization: getBearerToken() },
    })
    dispatch({ type: 'DELETE_APP_SUCCESS', data: { id } })
    return id

  } catch(e) {

    alert('Unsuccessful app deletion')
    console.error(e)

  }
}