import { lazy } from 'react';

 class BBSimplePushButtonDescriptor{
  
  static getDescription() {
    return {
      name: 'BBSimplePushButton',
      component: lazy(() =>  import('./BBSimplePushButton')), 
      category:"Default",
      label: 'Simple Push Button',
      variables: [
        {
          name: 'label',
          type: 'text',
          label: 'label',
        },
        {
          name: 'beginIcon',
          type: 'text',
          label: 'begin icon',
        },
        {
          name: 'endIcon',
          type: 'text',
          label: 'end icon',
        },
        {
          name: 'enabled',
          type: 'text',
          label: 'parameter true/false for enabling button',
        },

        {
          name: 'tooltip',
          type: 'text',
          label: 'button tooltip',
        },

        {
          name: 'query',
          type: 'yasgui',
          label: 'for parameters for label for example',
        },
        {
          name: 'link',
          type: 'text',
          label: 'simple link which will be called when the button is clicked',
        },
        {
          name: 'postLink',
          type: 'text',
          label:
            'alternative to link: a post link with optionally a postObject. keywords allowed such as command for command servlet calls',
        },
        {
          name: 'postObject',
          type: 'text',
          label:
            'object send via postLink adres. Parameters will be resolved before sending. keep it simple. no subchildren',
        },
        {
          name: 'postDownload',
          type: 'boolean',
          label: 'triggers a download',
        },
        {
          name: 'downloadFileName',
          type: 'text',
          label: 'download file name ',
        },
        {
          name: 'messageLWTTitle',
          type: 'text',
          label: 'show message title for long waiting time',
        },
        {
          name: 'messageLWT',
          type: 'text',
          label: 'show message for long waiting time',
        },
        
        
        {
          name: 'changeParameter',
          type: 'text',
          label: 'change parameter value when succesfull',
        },
        {
          name: 'setInitialValueChangeParameter',
          type: 'boolean',
          label: 'set initial value for change parameter ',
        },
        {
          name: 'successMessage',
          type: 'text',
          label: 'shows message after successful operation ',
        },
        {
          name: 'fontSize',
          type: 'text',
          label: 'size of the text ',
        },
        {
          name: 'sparqlqueryrapport',
          type: 'yasgui',
          label: 'sparql query rapport. (optionally use #template:)',
        },




      ],
    }
  }
}
  
export default BBSimplePushButtonDescriptor
