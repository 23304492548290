export function getCgiUrl(
  cgiService: '' | 'login' | 'logout' | 'isloggedin' | 'sparql',
  repoName: null | string,
  repoUrl: string,
  localRepoUrl?: string
): string {
  const absoluteRepoUrlMaybeLegacy = getAbsoluteRepoUrl(repoUrl, localRepoUrl)
  const absoluteRepoUrl = fixupLegacyRepoURL(absoluteRepoUrlMaybeLegacy, repoName)
  return absoluteRepoUrl.replace('sparql', cgiService)
}

export function getAbsoluteRepoUrl(repoUrl: string, localRepoUrl?: string) {
  const { origin } = window.location
  const url = origin.includes('localhost') && localRepoUrl ? localRepoUrl : repoUrl

  if (url.startsWith('http://') || url.startsWith('https://')) return url
  if (url.startsWith('/')) return origin + url
  return `${origin}/${url}`
}

/**
 * Automatically converts legacy repoURL string into correct repoURL string
 * such that it will always end with `servlets/cgi/sparql/${repoName}`
 */
export function fixupLegacyRepoURL(repoUrl: string, repoName: null | string) {
  if (!repoUrl) return repoUrl
  if (!isProbablyAWistorServletsEndpoint(repoUrl)) return repoUrl
  if (!repoName) return repoUrl
  return repoUrl.endsWith('/')
    ? repoUrl + repoName
    : repoUrl + '/' + repoName
}

function isProbablyAWistorServletsEndpoint(repoUrl: string) {
  return /servlets\/cgi\/sparql\/?$/.test(repoUrl);
}
